<template>
    <defaultSec :title="'< 返回'" :returnState="true">
        <div>
            <el-form :model="form" label-width="120px">
                <el-form-item label="属性id" prop="propertyId" v-show="false">
                    <el-input v-model="form.id" placeholder="请输入属性id"></el-input>
                </el-form-item>
                <el-form-item label="名称" prop="propertyName" :required="true">
                    <el-input v-model="form.sceneName" placeholder="属性名称"></el-input>
                </el-form-item>
                <el-form-item label="sql" prop="propertyField"  :required="true">
                    <el-input v-model="form.sceneBigSql"  type="textarea"
                              :rows="5" placeholder="属性表字段"></el-input>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" @click="save" size="mini" class="header-btn">
                        保存
                    </el-button>
                </el-form-item>
            </el-form>
        </div>
    </defaultSec>
</template>

<script>
    import defaultSec from '@c/defaultSection'
    import tablePagination from '@c/tablePagination'
    export default {
        data() {
            return {
                form: {
                    id: this.$route.params.id || 0,
                    sceneName:'',
                    sceneBigSql:''
                },
                total: 0
            }
        },
        components: {
            defaultSec, tablePagination
        },
        mounted(){
            if(this.form.id>0){
                this.getDetail()
            }
        },
        methods: {
            getDetail() {
                this.$apiGet('lechun-cms/scene/getSceneEntity', {id: this.form.id}).then(res => {
                    this.form = res
                })
            },
            save(){
                this.$apiPost('lechun-cms/scene/saveSceneEntity', this.form).then(res => {
                    console.log(res)
                    this.$message({
                        message: '保存成功',
                        type: 'success'
                    });
                    //this.$router.push({path: '/sceneList', name: 'sceneList'})
                })
            }
        }
    }
</script>


<style scoped>
    .expand-title {
        width: 70px;
        color: #444548;
        line-height: 30px;
        text-align: right;
        margin-right: 10px;
        font-weight: 600;
    }

    .expand-flex {
        justify-content: flex-start;
        align-items: center;
        align-content: center;
    }
</style>